<template>
  <b-card no-body>
    <b-overlay
      variant="white"
      :show="show"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card-header>
        <h5 class="mb-1">Gerçekleşen Randevu Oranı</h5>

        <b-card-text class="font-medium-5">
          <b-dropdown
            size="sm"
            class="budget-dropdown mr-2"
            variant="outline-primary"
            :text="selectedType.name"
          >
            <b-dropdown-item
              v-for="typ in types"
              :key="typ.id"
              @click="selectType(typ)"
            >
              {{ typ.name }}
            </b-dropdown-item>
          </b-dropdown>
          <feather-icon
            icon="RefreshCcwIcon"
            size="21"
            class="text-muted cursor-pointer"
            @click="refresh"
          />
        </b-card-text>
      </b-card-header>

      <!-- apex chart -->
      <vue-apex-charts
        type="radialBar"
        height="250"
        :options="goalOverviewRadialBar.chartOptions"
        :series="goalOverviewRadialBar.series"
      />

      <!-- Progress Overview -->
      <b-row class="text-center">
        <b-col
          cols="4"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text mb-1">Gerçekleşen</b-card-text>
          <h3 class="font-weight-bolder">
            {{ goal_overview.success | numberFormat }}
          </h3>
        </b-col>

        <b-col
          cols="4"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text mb-1">Toplam Randevu</b-card-text>
          <h3 class="font-weight-bolder">
            {{ goal_overview.total | numberFormat }}
          </h3>
        </b-col>
        <b-col
          cols="4"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text mb-1">İptal Edilen </b-card-text>
          <h3 class="font-weight-bolder">
            {{ goal_overview.cancel | numberFormat }}
          </h3>
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BRow, BCol, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    ToastificationContent,
  },
  data() {
    return {
      goal_overview: {
        success: 0, // Tamamlanan görev sayısı
        cancel: 0, // Devam eden görev sayısı
        total: 0,
      },
      goalOverviewRadialBar: {
        series: [], // Progress percentage
        chartOptions: {
          chart: {
            sparkline: { enabled: true },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ["#51e5a8"],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: { size: "77%" },
              track: {
                background: "#ebe9f1",
                strokeWidth: "50%",
              },
              dataLabels: {
                name: { show: false },
                value: {
                  color: "#5e5873",
                  fontSize: "2.86rem",
                  fontWeight: "600",
                },
              },
            },
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: { lineCap: "round" },
          grid: { padding: { bottom: 30 } },
        },
      },
      types: [
        { id: 1, name: "Dün" },
        { id: 2, name: "Bu Hafta" },
        { id: 3, name: "Bu Ay" },
        { id: 4, name: "Son 30 Gün" },
      ],
      selectedType: { id: 2, name: "Bu Hafta" }, // Varsayılan olarak "Dün" seçili
    };
  },
  created() {
    var typ = this.selectedType.id;
    this.getRandevuOran(typ);

    // Sayfa yüklendiğinde Ocak ayı ile başlayalım
  },
  methods: {
    selectType(typ) {
      this.selectedType = typ; // Seçili türü güncelle
      this.getRandevuOran(typ.id); // Seçilen türün ID'si ile API çağrısı
    },
    async refresh() {
      this.goalOverviewRadialBar.series = [0];
      this.getRandevuOran(this.selectedType.id);
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Başarılı`,
          icon: "CoffeeIcon",
          variant: "success",
        },
      });
    },
    async getRandevuOran(typ) {
      this.show = true;
      this.$http
        .get("randevu/randevu-success-rate?type=" + typ)
        .then((response) => {
          this.show = false;
          const resp = response.data;
          this.goal_overview.success = resp.successfulRandevular; // başarılı randevular
          this.goal_overview.cancel = resp.cancelledRandevular; // iptal edilenler
          this.goal_overview.total = resp.totalRandevular; // total randevular
          this.goalOverviewRadialBar.series = [];
          this.goalOverviewRadialBar.series.push(resp.successRate);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.message,
            },
          });
        });
    },
  },
};
</script>
