<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card v-if="tableData" no-body class="card-company-table">
      <div style="overflow-x: auto">
        <b-table :items="tableData" responsive="sm" striped hover>
          <template #cell(serviceName)="data">
            <div class="d-flex align-items-center">
              <div
                :class="[
                  'avatar rounded-circle',
                  getServiceAvatar(data.item.serviceName).color,
                ]"
                style="
                  width: 40px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <feather-icon
                  :icon="getServiceAvatar(data.item.serviceName).icon"
                  size="18"
                />
              </div>
              <span class="ml-1">{{ data.item.serviceName }}</span>
            </div>
          </template>

          <template #cell()="data">
            <div class="d-flex align-items-center justify-content-center">
              <span class="font-weight-bolder mr-2 text-lg">{{
                data.value
              }}</span>
              <feather-icon
                v-if="typeof data.value === 'number'"
                :icon="getIcon(data.value)"
                :class="getIconColor(data.value)"
                class="icon-lg"
              />
            </div>
          </template>
        </b-table>
      </div>
      <div class="d-flex align-items-center justify-content-end mr-2">
        <feather-icon
          icon="RefreshCcwIcon"
          size="21"
          class="text-muted cursor-pointer"
          @click="getRandevuBrans"
        />
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BTable,
  BOverlay,
  BContainer,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BContainer,
    BCard,
    BCardHeader,
    BTable,
    BOverlay,
    ToastificationContent,
  },
  data() {
    return {
      show: false,
      tableData: [],
      date: "",
      fields: [
        {
          key: "serviceName",
          label: "Branşlar",
          sortable: true,
          class: "text-left",
        },
        {
          key: "Kocaeli",
          label: "Kocaeli",
          sortable: true,
          class: "text-center",
        },
        {
          key: "Samsun",
          label: "Samsun",
          sortable: true,
          class: "text-center",
        },
        { key: "Bursa", label: "Bursa", sortable: true, class: "text-center" },
        { key: "İzmir", label: "İzmir", sortable: true, class: "text-center" },
        {
          key: "Ankara",
          label: "Ankara",
          sortable: true,
          class: "text-center",
        },
        { key: "Fulya", label: "Fulya", sortable: true, class: "text-center" },
        {
          key: "Çamlıca",
          label: "Çamlıca",
          sortable: true,
          class: "text-center",
        },
        {
          key: "Montenegro",
          label: "Montenegro",
          sortable: true,
          class: "text-center",
        },
      ],
    };
  },
  created() {
    this.getRandevuBrans();
  },
  methods: {
    async getRandevuBrans() {
      this.show = true;
      try {
        const response = await this.$http.get("randevu/randevu-hastane-brans");
        this.show = false;
        this.transformData(response.data);
        this.date = response.data[0].date;
      } catch (error) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Hata",
            icon: "CoffeeIcon",
            variant: "danger",
            text: error.response.message || "Bir hata oluştu",
          },
        });
      }
    },
    transformData(data) {
      this.tableData = data.map((item) => {
        return {
          serviceName: item.serviceName,
          ...item.branchCounts,
        };
      });
    },
    getIcon(value) {
      if (value < 10) {
        return "TrendingDownIcon"; // Aşağı ok
      } else if (value >= 10 && value <= 30) {
        return "MinusIcon"; // Düz çizgi
      } else {
        return "TrendingUpIcon"; // Yukarı ok
      }
    },
    getIconColor(value) {
      if (value < 10) {
        return "text-danger"; // Kırmızı
      } else if (value >= 10 && value <= 30) {
        return "text-warning"; // Sarı veya nötr
      } else {
        return "text-success"; // Yeşil
      }
    },
    getServiceAvatar(serviceName) {
      const avatarConfigs = {
        "Fiziksel Tıp ve Rehabilitasyon": {
          icon: "MonitorIcon",
          color: "bg-light-primary",
        },
        Radyoloji: { icon: "ActivityIcon", color: "bg-light-success" },
        Nöroloji: { icon: "UserIcon", color: "bg-light-warning" },
        "Spor Hekimliği": { icon: "ActivityIcon", color: "bg-light-danger" },
        Romatoloji: { icon: "HeartIcon", color: "bg-light-info" },
        "Beslenme ve Diyetisyen": {
          icon: "PieChartIcon",
          color: "bg-light-warning",
        },
        "İç Hastalıkları": { icon: "TrendingUpIcon", color: "bg-light-info" },
      };
      return (
        avatarConfigs[serviceName] || {
          icon: "CircleIcon",
          color: "bg-light-muted",
        }
      );
    },
  },
};
</script>
<style scoped>
.b-table tr th,
.b-table tr td {
  white-space: nowrap; /* Hücrelerin tek satırda kalmasını sağlar */
}

.avatar {
  min-width: 40px; /* Avatar boyutunu korur */
  min-height: 40px;
}

.icon-lg {
  font-size: 1.5rem;
}

.text-lg {
  font-size: 1.1rem;
}
</style>
