<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-card class="m-0 p-0">
      <b-card-header class="m-0 p-0">
        <b-card-title>
          <h5 class="mb-1">Toplam Randevu Sayıları ve İptal İstatistikleri</h5>
        </b-card-title>

        <feather-icon
          icon="RefreshCcwIcon"
          size="21"
          class="text-muted cursor-pointer"
          @click="getLast15Days"
        />
      </b-card-header>
      <b-card-body class="m-0 p-0">
        <vue-apex-charts
          :key="chartKey"
          type="line"
          height="400"
          :options="lineChartOptions.chartOptions"
          :series="lineChartOptions.series"
        />
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    ToastificationContent,
  },
  data() {
    return {
      show: false,
      chartKey: 0,
      lineChartOptions: {
        height: 400,
        type: "line", // Çizgi grafiği
        series: [], // Seriler API'den doldurulacak
        chartOptions: {
          chart: {
            zoom: { enabled: true },
            toolbar: { show: true },
          },
          colors: ["#00C851", "#ff4444"],
          dataLabels: {
            enabled: true,
            style: {
              colors: ["#00C851", "#ff4444"],
            },
            background: {
              enabled: true,
            },
          },
          stroke: {
            curve: "straight",
            width: 2,
          },
          xaxis: {
            categories: [],
            labels: {
              show: false, // Tarih etiketlerini gizler
            },
            title: { text: "" }, // X ekseni başlığını boş bırakabilirsiniz
          },
          yaxis: {
            title: { text: "" },
          },
          tooltip: {
            shared: false,
            intersect: false,
          },
          grid: {
            borderColor: "#f1f3fa",
            xaxis: { lines: { show: true } },
          },
          responsive: [
            {
              breakpoint: 480, // 480px ve altı için ayarlar
              options: {
                chart: {
                  height: 300, // Grafiği daha kompakt hale getirin
                },
                legend: {
                  position: "bottom", // Legend alt kısma alınabilir
                },
                dataLabels: {
                  style: {
                    fontSize: "10px", // Yazı boyutlarını küçültün
                  },
                },
                xaxis: {
                  labels: {
                    rotate: -45, // X ekseni etiketlerini döndürerek sığdırın
                    style: {
                      fontSize: "10px",
                    },
                  },
                },
              },
            },
          ],
        },
      },
    };
  },
  created() {
    this.getLast15Days();

    // Sayfa yüklendiğinde Ocak ayı ile başlayalım
  },
  methods: {
    async getLast15Days() {
      this.show = true;
      this.chartKey += 0;
      this.$http
        .get("randevu/randevu-chart-15")
        .then((response) => {
          this.show = false;
          const data = response.data;
          this.lineChartOptions.chartOptions.xaxis.categories = data.categories;
          // API'den gelen seriler ve kategorileri uygun formatta güncelle
          this.lineChartOptions.series = data.series.map((series) => ({
            name: series.name,
            data: series.dataPoints.map((point) => point.value),
          }));
          this.chartKey += 1;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.message,
            },
          });
        });
    },
  },
};
</script>
