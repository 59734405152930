<template>
  <b-card no-body class="card-revenue-budget">
    <b-overlay
      variant="white"
      :show="show"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-row class="mx-0">
        <b-col class="revenue-report-wrapper">
          <div
            class="d-sm-flex justify-content-between align-items-center mb-3"
          >
            <h4 class="card-title mb-50 mb-sm-0">Aylık Randevular</h4>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center mr-2">
                <span
                  class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer"
                ></span>
                <span>Aktif Randevu</span>
              </div>
              <div class="d-flex align-items-center">
                <span
                  class="bullet bullet-danger svg-font-small-3 mr-50 cursor-pointer"
                ></span>
                <span>İptal Randevu</span>
              </div>
              <div class="d-flex align-items-center ml-2">
                <b-dropdown
                  size="sm"
                  class="budget-dropdown"
                  variant="outline-primary"
                  :text="
                    selectedMonthIndex !== null
                      ? months[selectedMonthIndex]
                      : 'Bir Ay Seçin'
                  "
                >
                  <b-dropdown-item
                    v-for="(month, index) in months"
                    :key="index"
                    @click="selectMonth(index)"
                  >
                    {{ month }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="d-flex align-items-center ml-2">
                <b-card-text class="font-medium-5 mb-0">
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="21"
                    class="text-muted cursor-pointer"
                    @click="refresh"
                  />
                </b-card-text>
              </div>
            </div>
          </div>

          <!-- ApexCharts ile Haftalık Randevular Çubuk Grafiği -->
          <vue-apex-charts
            id="revenue-report-chart"
            type="bar"
            height="230"
            :key="chartKey"
            :options="chartOptions"
            :series="seriesData"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      chartKey: 0, // Chart için key değeri
      months: [
        "Ocak",
        "Şubat",
        "Mart",
        "Nisan",
        "Mayıs",
        "Haziran",
        "Temmuz",
        "Ağustos",
        "Eylül",
        "Ekim",
        "Kasım",
        "Aralık",
      ],
      selectedMonthIndex: null, // Seçilen ayın indeksini tutuyor
      totalAppointments: 0, // Toplam randevu sayısı (Aktif + İptal)
      budget: 56800, // Bütçe bilgisi
      // Büyük Çubuk Grafik için Chart Options ve Dummy Data
      chartOptions: {
        chart: {
          stacked: true,
          type: "bar",
          toolbar: { show: false },
        },
        grid: {
          padding: {
            top: -20,
            bottom: -10,
          },
          yaxis: {
            lines: { show: true },
          },
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: "#000000",
            },
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        colors: [$themeColors.success, $themeColors.danger],
        plotOptions: {
          bar: {
            columnWidth: "12%",
            endingShape: "rounded",
          },
          distributed: true,
        },
        yaxis: {
          labels: {
            style: {
              colors: "#000000",
            },
          },
        },
      },
      seriesData: [
        {
          name: "Aktif Randevu",
          data: [], // Aktif randevu verileri burada tutulacak
        },
        {
          name: "İptal Randevu",
          data: [], // İptal randevu verileri burada tutulacak
        },
      ],
      // Küçük Grafik için Dummy Data (Line Chart)
      smallChartOptions: {
        chart: {
          type: "line",
          toolbar: { show: false },
        },
        xaxis: {
          categories: ["Hafta 1", "Hafta 2", "Hafta 3", "Hafta 4"],
        },
        yaxis: {
          title: {
            text: "Randevu",
          },
        },
        colors: ["#7367F0", "#FF9F43"],
      },
      smallSeriesData: [
        {
          name: "Aktif Randevular",
          data: [], // Küçük grafik için aktif randevular
        },
        {
          name: "İptal Randevular",
          data: [], // Küçük grafik için iptal randevular
        },
      ],
      // Aylık ve Haftalık Dummy Data
      dummyData: {},
    };
  },
  created() {
    //this.getRandevu();
    // Sayfa yüklendiğinde Ocak ayı ile başlayalım
  },
  methods: {
    async refresh() {
      this.getRandevu();
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: `Başarılı`,
          icon: "CoffeeIcon",
          variant: "success",
        },
      });
    },
    async getRandevu() {
      this.show = true;
      this.$http
        .get("randevu/randevu-monthly-summary")
        .then((response) => {
          this.show = false;
          this.dummyData = response.data;
          this.selectMonth(0);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    // Ay seçildiğinde çalışacak method
    selectMonth(index) {
      this.selectedMonthIndex = index;
      this.updateChart();
    },
    // Grafiği ve verileri güncelleme methodu
    updateChart() {
      this.show = true;
      const data = this.dummyData[this.selectedMonthIndex] || [];
      const categories = data.map((item) => item.week);
      const activeData = data.map((item) => item.active);
      const cancelledData = data.map((item) => item.cancelled);

      // Grafikteki x eksenini (haftalar) ve serileri (aktif/iptal) güncelle
      this.chartOptions.xaxis.categories = categories;
      // ChartOptions'u Vue.set ile reaktif olarak güncelle
      this.$set(this.chartOptions.xaxis, "categories", categories);
      this.seriesData = [
        {
          name: "Aktif Randevular", // Aktif randevular
          data: activeData, // Aktif randevu verileri
        },
        {
          name: "İptal Randevular", // İptal randevular
          data: cancelledData, // İptal randevu verileri
        },
      ];
      // Key'i arttırarak chart'ı yeniden render et
      this.chartKey += 1;
      console.log(this.seriesData);

      // Toplam randevuların hesaplanması
      this.totalAppointments =
        activeData.reduce((acc, val) => acc + val, 0) +
        cancelledData.reduce((acc, val) => acc + val, 0);

      // Küçük grafik için de veriler güncellenir
      this.smallSeriesData[0].data = activeData;
      this.smallSeriesData[1].data = cancelledData;
      this.show = false;
    },
  },
};
</script>
