<template>
  <b-overlay
    variant="white"
    :show="show"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <b-row>
      <b-col cols="12" md="12" lg="9">
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <b-card>
              <b-card-title class="text-center mt-1">
                Dün Gerçekleşme Durumu
              </b-card-title>
              <hr class="mt-2 mb-2" />
              <div class="d-flex justify-content-between">
                <div class="text-center">
                  <b-card-text class="mb-2"> Gerçekleşen </b-card-text>
                  <span class="font-large-1 font-weight-bold">
                    {{ randevuTarihliResponse.okCount }}
                  </span>
                </div>
                <div class="text-center">
                  <b-card-text class="mb-2"> İptal Edilen </b-card-text>
                  <span class="font-large-1 font-weight-bold">
                    {{ randevuTarihliResponse.iptalCount }}
                  </span>
                </div>
                <div class="text-center">
                  <b-card-text class="mb-2"> Tümü </b-card-text>
                  <span class="font-large-1 font-weight-bold">
                    {{ randevuTarihliResponse.totalCount }}
                  </span>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" md="6" lg="8">
            <b-card class="p-0">
              <b-card-header class="mb-1 p-0">
                <span>Gerçekleşen Randevu Sayıları</span>

                <b-card-text>
                  <span>{{ subeRandevuSayilari.date | formatDate }} </span>
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="15"
                    class="text-muted cursor-pointer"
                    @click="getSubelerRandevuTarihi"
                  />
                </b-card-text>
              </b-card-header>

              <b-row>
                <b-col
                  v-for="branch in subeRandevuSayilari.branchItems"
                  :key="branch.branchId"
                  cols="6"
                  sm="6"
                  md="4"
                  lg="3"
                  class="mb-1"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-avatar
                        size="38"
                        :variant="getVariant(branch.totalCount)"
                      >
                        <feather-icon size="22" icon="CalendarIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder">
                        {{ branch.totalCount }}
                        <feather-icon
                          size="18"
                          :icon="getArrowIcon(branch.totalCount)"
                          :class="getTextVariant(branch.totalCount)"
                        />
                      </h4>
                      <b-card-text class="font-small-4 m-0">
                        {{ branch.branchName }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" md="6" lg="4">
            <b-card>
              <b-card-title class="text-center mt-0">
                Dün Verilen Randevular
              </b-card-title>
              <hr class="mt-2 mb-2" />
              <div class="d-flex justify-content-between">
                <div class="text-center">
                  <b-card-text class="mb-2"> Randevu Sayısı </b-card-text>
                  <span class="font-large-1 font-weight-bold">
                    {{ kayitTarihliResponse.totalCount }}
                  </span>
                </div>
                <div class="text-center">
                  <b-card-text class="mb-2"> Gelecek İptaller </b-card-text>
                  <span class="font-large-1 font-weight-bold">
                    {{ kayitTarihliResponse.iptalCount }}
                  </span>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col cols="12" md="6" lg="8">
            <b-card class="p-0">
              <b-card-header class="mb-1 p-0">
                <span>Dün Verilen Randevular</span>

                <b-card-text>
                  <span>{{ subeRandevuKayitSayilari.date | formatDate }} </span>
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="15"
                    class="text-muted cursor-pointer"
                    @click="getSubelerRandevuTarihi"
                  />
                </b-card-text>
              </b-card-header>

              <b-row>
                <b-col
                  v-for="branch in subeRandevuKayitSayilari.branchItems"
                  :key="branch.branchId"
                  cols="6"
                  sm="6"
                  md="4"
                  lg="3"
                  class="mb-1"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-1">
                      <b-avatar
                        size="38"
                        :variant="getVariant(branch.totalCount)"
                      >
                        <feather-icon size="22" icon="CalendarIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ branch.totalCount }}
                        <feather-icon
                          size="18"
                          :icon="getArrowIcon(branch.totalCount)"
                          :class="getTextVariant(branch.totalCount)"
                        />
                      </h4>
                      <b-card-text class="font-small-4 mb-0">
                        {{ branch.branchName }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" md="12" lg="3">
        <randevularCh />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="12" lg="12">
        <randevu15gun />
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col lg="12">
        <randevuHastaneBrans />
      </b-col>
    </b-row>
    <b-row class="match-height mt-1">
      <b-col cols="12" md="12" lg="12">
        <randevuHBP />
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import randevuAylikHaftalik from "../Component/randevularAylikHaftalar.vue";
import randevularCh from "../Component/randevularChart.vue";
import randevu15gun from "../Component/randevular15gun.vue";
import randevuHastaneBrans from "../Component/randevuHastaneBrans.vue";
import randevuHBP from "../Component/randevuHastaneBransFiyat.vue";
export default {
  components: {
    randevuAylikHaftalik,
    randevularCh,
    randevu15gun,
    randevuHastaneBrans,
    randevuHBP,
  },
  data() {
    return {
      show: false,
      startDate: "", // Başlangıç tarihi
      endDate: "", // Bitiş tarihi
      appointments: [], // API'den gelen veriler
      pagerOptions: {
        visible: true,
        showPageSizeSelector: true,
        allowedPageSizes: [50, 100, 200],
        showInfo: true,
        showNavigationButtons: true,
      },
      randevuTarihliResponse: {
        totalCount: 0,
        okCount: 0,
        iptalCount: 0,
        date: "",
      },
      kayitTarihliResponse: {
        totalCount: 0,
        okCount: 0,
        iptalCount: 0,
        date: "",
      },
      subeRandevuSayilari: {
        branchItems: [],
        date: "",
      },
      subeRandevuKayitSayilari: {
        branchItems: [],
        date: "",
      },
    };
  },
  created() {
    this.getRandevuTarihi();
    this.getKayitTarihi();
    this.getSubelerRandevuTarihi();
    this.getSubelerRandevuKayitTarihi();
    // Sayfa yüklendiğinde Ocak ayı ile başlayalım
  },
  methods: {
    async fetchData() {
      this.show = true;
      this.$http
        .get("randevu", {
          params: {
            startDate: this.startDate,
            endDate: this.endDate,
          },
        })
        .then((response) => {
          this.show = false;
          this.appointments = response.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    //randevu-sayisi-gerceklesme
    async getRandevuTarihi() {
      this.show = true;
      this.$http
        .get("randevu/randevu-sayisi-gerceklesme")
        .then((response) => {
          this.show = false;
          this.randevuTarihliResponse = response.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    //randevu-sayisi-kayit
    async getKayitTarihi() {
      this.show = true;
      this.$http
        .get("randevu/randevu-sayisi-kayit")
        .then((response) => {
          this.show = false;
          this.kayitTarihliResponse = response.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    //randevu-sube-kayitlari Gerçekleme tarihi
    async getSubelerRandevuTarihi() {
      this.show = true;
      this.$http
        .get("randevu/randevu-sube-kayitlari")
        .then((response) => {
          this.show = false;
          this.subeRandevuSayilari = response.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    //randevu-sube-kayitlari-kayit-tarihi
    async getSubelerRandevuKayitTarihi() {
      this.show = true;
      this.$http
        .get("randevu/randevu-sube-kayitlari-kayit-tarihi")
        .then((response) => {
          this.show = false;
          this.subeRandevuKayitSayilari = response.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Hata`,
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.message,
            },
          });
        });
    },
    getVariant(count) {
      if (count < 10) {
        return "light-danger"; // Kırmızı
      } else if (count >= 10 && count < 30) {
        return "light-warning"; // Sarı
      } else {
        return "light-success"; // Yeşil
      }
    },
    getArrowIcon(count) {
      if (count < 10) {
        return "TrendingDownIcon"; // Aşağı ok
      } else if (count >= 10 && count < 30) {
        return "ActivityIcon"; // Aktivite simgesi
      } else {
        return "TrendingUpIcon"; // Yukarı ok
      }
    },
    getTextVariant(count) {
      if (count < 10) {
        return "text-danger";
      } else if (count >= 10 && count < 30) {
        return "text-warning";
      } else {
        return "text-success";
      }
    },
  },
};
</script>
